<template>
    <v-form class="wrapperForm" v-if="dataSource">
        <v-row dense>
            <v-col cols="12" sm="12" md="12" class="section-wrapper">
                <v-card flat>
                    <v-card-text>
                        <div class="form-box-title">{{$t("Ход_работы")}}</div>
                        
                        <template>
                             <v-simple-table class="customization-list-table customization-history-list-table">
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left" width="150px">
                                                {{$t("Дата")}}
                                            </th>
                                            <th class="text-left" width="15%">
                                                {{$t("Автор")}}
                                            </th>
                                            <th class="text-left">
                                                {{$t("Сообщение")}}
                                            </th>
                                        </tr>
                                    </thead>
                                </template>  
                             </v-simple-table>  
                            <v-expansion-panels 
                                mandatory
                                flat
                                accordion
                                tile
                                hover
                                focusable
                                class="history-ex-panel"
                            >
                                <v-expansion-panel
                                    v-for="(dataSourceItem,i) in dataSource"
                                    :key="i"
                                >
                                <v-expansion-panel-header>
                                    {{ dataSourceItem.name }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                   <v-simple-table class="customization-list-table">
                                        <template v-slot:default>
                                            <tbody>
                                                <tr
                                                    v-for="itemChildren in dataSourceItem.children"
                                                    :key="itemChildren.id"
                                                >
                                                    <td width="150px">{{ itemChildren.date }}</td>
                                                    <td width="15%">
                                                        <v-employee-chip 
                                                            v-if="itemChildren.author && itemChildren.authorId !== '31111111-1111-1111-1111-444444444444'" 
                                                            :id="itemChildren.authorId" 
                                                            :name="itemChildren.author" 
                                                        />
                                                        <v-workplace-chip
                                                            v-else
                                                            :name="$t('Система')"
                                                            :formatter="(name) => { return name; }"
                                                            icon="$integroIcon"
                                                            iconClass="integroIcon"
                                                        />
                                                    </td>
                                                    <td>
                                                        <div class="etw-text-container">
                                                            <div class="etw-text" v-tooltip.top-center="itemChildren.message">
                                                                {{itemChildren.message}}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <!--<td></td>-->
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table> 
                                </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                        </template>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";

export default {
    name: "history-tab",
    props: {
        id: {
            type: String,
            default: null,
            opened: []
        }
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null
        }
    },
    computed: {
        openedItems: {
            get: function () {
                return this.dataSource.map(function (x) {
                    return x.id;
                });
            },
            set: function (newValue) {
                this.opened = newValue;
            }                
        }
    },
    methods: {
        async update() {
            if (this.cancellationTokenSorce)
                this.cancellationTokenSorce.cancel('New request started');

            this.cancellationTokenSorce = Axios.CancelToken.source();

            let response = await httpAPI({
                url: `api/tabs/history/${this.$props.id}`,
                method: 'GET',
                headers: { 'isCommon': this.$store.getters['global/actionsource/isDataSourceCommon'] },
                cancelToken: this.cancellationTokenSorce.token,
            });

            this.dataSource = response?.data.Payload;
        },
        isFirstInGroup(item) {
            let group = this.dataSource.find(x => x.children.find(child => child.id === item.id) != null );
            return group.children.indexOf(item) === 0;
        },
        isLastInGroup(item) {
            let group = this.dataSource.find(x => x.children.find(child => child.id === item.id) != null );
            return group.children.indexOf(item) + 1 === group.children.length;
        }
    },
    async created(){
        await this.update();
    },
    mounted() {        
        this.$eventBus.$on('update-active-tab', async () => {
            if (this.$parent.isActive)
                await this.update();
        });
    },
    beforeDestroy() {
        this.$eventBus.$off('update-active-tab');
    }
}
</script>
